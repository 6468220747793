/* eslint-disable import/prefer-default-export */

const faqs = [
  {
    id: 1,
    question: 'Hoe weet ik welke abonnementen er opgezegd of overgenomen moeten worden?',
    answer:
      'Bankafschriften en post geven je inzicht in lopende abonnementen, contracten en accounts. Daarnaast kan een e-mailinbox ook helpen bij het creëren van een overzicht. Wij hebben alle organisaties overzichtelijk gecategoriseerd in onze checklist, zodat je niets over het hoofd ziet.'
  },
  {
    id: 2,
    question:
      'Hoeveel tijd neemt het in beslag voordat alle abonnementen zijn opgezegd en overgenomen?',
    answer:
      'Gemiddeld duurt het 10 werkdagen voordat álle abonnementen zijn opgezegd en/of overgenomen. De afhandelingstijd is afhankelijk van de organisaties. Gemiddeld wordt een opzegging binnen 5 werkdagen behandeld. Een overname duurt gemiddeld 10 werkdagen. In je persoonlijke account kan je precies zien wat de huidige status is per opzegging of overname.'
  },
  {
    id: 3,
    question: 'Hoe krijg ik toegang tot de rekening van de overleden persoon?',
    answer:
      'Selecteer in de checklist de betreffende bank. Closure zal de bank vervolgens informeren over het overlijden. De bank zal daarna contact met je opnemen om samen te kijken welke documenten er nodig zijn om jou toegang te kunnen verlenen tot de rekening.'
  },
  {
    id: 4,
    question:
      'Wat moet mijn relatie tot de overleden persoon zijn om gebruik te maken van Closure?',
    answer:
      'Je kunt Closure gebruiken als je verantwoordelijk bent voor de afhandeling van de erfenis. Dit kan zijn wanneer je bent benoemd tot executeur (testamentair) of als je (door bijvoorbeeld de nabestaanden) bent aangewezen om dit regelen.'
  },
  {
    id: 5,
    question:
      'Welke gegevens en documenten heb ik nodig om te beginnen met het opzeggen, wijzigen of overdragen van abonnementen?',
    answer:
      'Als nabestaande heb je enkel de persoonsgegevens van de overledene en een akte van overlijden nodig. De akte van overlijden krijg je meestal van de uitvaartondernemer en anders is deze ook verkrijgbaar bij de gemeente waar de persoon is overleden. Heb je op dit moment de akte van overlijden nog niet bij de hand? Dan kan je die ook op een later moment aan jouw account toevoegen.'
  },
  {
    id: 6,
    question: 'Is Closure onderdeel van een grotere organisatie?',
    answer:
      'Nee, Closure is een onafhankelijke organisatie. Wij werken wel samen met organisaties zoals T-Mobile, Ardanta en uitvaartondernemers. Zij vinden het belangrijk om nabestaanden na het verlies van een naaste te ondersteunen en werken daarom samen met Closure.'
  },
  {
    id: 7,
    question: 'Welke diensten kan ik via Closure opzeggen of overdragen?',
    answer:
      'Je kunt via Closure alle abonnementen, contracten en accounts opzeggen, wijzigen of overdragen. Denk daarbij aan bankrekeningen, goede doelen, energiecontracten en social media accounts. Wij hebben alle organisaties overzichtelijk gecategoriseerd zodat je niets over het hoofd ziet. Mis je een organisatie in het overzicht? Neem dan contact op met onze klantenservice via onze chat, e-mail of telefoonnummer.'
  },
  {
    id: 8,
    question: 'Heb ik wachtwoorden, klantnummers of gebruikersnamen nodig?',
    answer:
      'Nee, je hebt geen wachtwoorden, klantnummers of gebruikersnamen nodig om abonnementen via Closure op te zeggen of over te dragen.'
  },
  {
    id: 9,
    question: 'Kan ik op een later moment nog abonnementen toevoegen aan mijn overzicht?',
    answer:
      'Ja, dat is mogelijk. Er bestaat een kans dat je op een later moment nog andere organisaties tegenkomt op de bankrekening of tussen de post. Je kunt deze organisaties op elk gewenst moment eenvoudig toevoegen aan het overzicht in jouw account.'
  },
  {
    id: 10,
    question: 'Wat gebeurt er met mijn gegevens?',
    answer:
      'Wij gebruiken jouw gegevens enkel voor het regelen van de afwikkeling van abonnementen bij de door jouw geselecteerde organisaties. Wij hebben het beste voor met uw privacy en de veiligheid van de gegevens. Wij verkopen geen gegevens aan derden en gaan vertrouwelijk om met persoonlijke informatie in overeenstemming met geldende wet- en regelgeving. Daarnaast verwijderen wij de gegevens automatisch van onze servers binnen 6 maanden na de afronding van jouw verzoeken. Je kunt hier meer over lezen in ons privacybeleid.'
  },
  {
    id: 11,
    question: 'Hoe betaal ik voor Closure?',
    answer:
      'Je gebruikt onze diensten altijd via een van onze partners. In veel gevallen is de dienst daarom kosteloos voor nabestaanden.'
  },
  {
    id: 12,
    question: 'Closure is gratis, hoe kan dit?',
    answer:
      'Naast dat Closure jou als nabestaande ontzorgt, besparen wij kosten bij onze partners. De grote abonnementsbedrijven betalen dus voor de Closure dienst, zodat het voor nabestaanden gratis is.'
  },
  {
    id: 13,
    question: 'Ik kom op een website \'nabestaanden.support\'. Kan ik dit vertrouwen?',
    answer:
      'Closure verwerkt als organisatie de overlijdensmeldingen voor grote abonnementsbedrijven. Voor deze organisaties gebruiken wij het domein \'nabestaanden.support\' en mailen wij met \'@nabestaanden.support\'. Je kunt deze websites en e-mails vertrouwen.'
  }
];

const faqsFRBE = [
  {
    id: 1,
    question: 'Comment savoir quels abonnements doivent être annulés ou repris?',
    answer:
      'Les relevés bancaires et le courrier postal vous donnent une idée des abonnements, contrats et comptes en cours. De plus, une boîte e-mail peut également aider à créer un aperçu. Nous avons catégorisé de manière claire toutes les organisations dans notre liste de contrôle, afin que rien ne vous échappe.'
  },
  {
    id: 2,
    question: 'Combien de temps faut-il pour annuler et reprendre tous les abonnements?',
    answer:
      "En moyenne, il faut 10 jours ouvrables pour annuler et/ou reprendre tous les abonnements. Le délai de traitement dépend des organisations. En moyenne, une annulation est traitée dans les 5 jours ouvrables. Le transfert prend en moyenne 10 jours ouvrables. Vous pouvez voir l'état actuel de chaque annulation ou transfert dans votre compte personnel."
  },
  {
    id: 3,
    question: 'Comment accéder au compte de la personne décédée?',
    answer:
      "Sélectionnez la banque correspondante dans la liste de contrôle. Closure informera la banque du décès. La banque vous contactera ensuite pour déterminer les documents nécessaires pour vous accorder l'accès au compte."
  },
  {
    id: 4,
    question:
      'Quelles sont les relations que je dois avoir avec la personne décédée pour utiliser Closure?',
    answer:
      "Vous pouvez utiliser Closure si vous êtes responsable de la gestion de l'héritage. Cela peut être le cas lorsque vous avez été nommé exécuteur testamentaire ou que vous avez été désigné (par exemple par les proches) pour régler cela."
  },
  {
    id: 5,
    question:
      'Quelles informations et documents dois-je avoir pour commencer à résilier, modifier ou transférer des abonnements ?',
    answer:
      "En tant que proche, vous avez besoin des informations personnelles du défunt et d'un acte de décès. L'acte de décès est généralement fourni par l'entreprise de pompes funèbres, sinon il est disponible auprès de la mairie où la personne est décédée. Si vous n'avez pas encore l'acte de décès, vous pouvez l'ajouter à votre compte plus tard."
  },
  {
    id: 6,
    question: "Closure fait-il partie d'une plus grande organisation ?",
    answer:
      "Non, Closure est une organisation indépendante. Nous travaillons en collaboration avec des organisations telles que T-Mobile, Ardanta et des entreprises de pompes funèbres. Ils considèrent qu'il est important de soutenir les proches après la perte d'un être cher et travaillent donc en collaboration avec Closure."
  },
  {
    id: 7,
    question: 'Quelles services peut-on résilier ou transférer via Closure ?',
    answer:
      "Vous pouvez résilier, modifier ou transférer tous les abonnements, contrats et comptes via Closure. Il s'agit notamment de comptes bancaires, de bonnes causes, de contrats d'énergie et de comptes de médias sociaux. Nous avons catégorisé toutes les organisations de manière claire afin que vous ne manquiez rien. Si vous ne trouvez pas une organisation dans l'aperçu, veuillez contacter notre service clientèle via notre chat, notre e-mail ou notre numéro de téléphone."
  },
  {
    id: 8,
    question:
      "Ai-je besoin de mots de passe, numéros de client ou noms d'utilisateur pour résilier des abonnements?",
    answer:
      "Non, vous n'avez pas besoin de mots de passe, numéros de client ou noms d'utilisateur pour résilier des abonnements via Closure."
  },
  {
    id: 9,
    question: 'Puis-je ajouter des abonnements plus tard à mon aperçu?',
    answer:
      "Oui, c'est possible. Il est possible que vous rencontriez plus tard d'autres organisations sur le compte bancaire ou parmi les courriers. Vous pouvez facilement ajouter ces organisations à votre aperçu à tout moment dans votre compte."
  },
  {
    id: 10,
    question: 'Que se passe-t-il avec mes données?',
    answer:
      'Nous utilisons vos données uniquement pour la gestion des abonnements aux organisations que vous avez sélectionnées. Nous avons le meilleur pour votre vie privée et la sécurité des données. Nous ne vendons pas de données à des tiers et nous traitons les informations personnelles de manière confidentielle en conformité avec les lois et règlements applicables. En outre, nous supprimons automatiquement les données de nos serveurs dans les 6 mois suivant la fin de vos demandes. Vous pouvez en savoir plus sur cela dans notre politique de confidentialité.'
  },
  {
    id: 11,
    question: 'Comment puis-je payer pour Closure?',
    answer:
      "Vous utilisez toujours nos services via l'un de nos partenaires. Dans de nombreux cas, le service est donc gratuit pour les proches."
  },
  {
    id: 12,
    question: 'Closure est gratuit, comment est-ce possible?',
    answer:
      "En plus de décharger Closure en tant que proche, nous économisons des coûts chez nos partenaires. Les grands fournisseurs d'abonnements paient donc pour le service Closure, ce qui est gratuit pour les proches."
  }
];
export { faqs, faqsFRBE };
