const messages = {
  'languages.nl-NL': 'Naar Nederlands',
  'languages.nl-BE': 'Naar Nederlands',
  'languages.fr-BE': 'Au français',
  'languages.fr-FR': 'Au français',
  'url.algemene-voorwaarden': '/algemene-voorwaarden',
  'url.case-studies': '/case-studies',
  'url.contact': '/contact',
  'url.organisaties': '/organisaties',
  'url.partners': '/partners',
  'url.pers-en-media': '/pers-en-media',
  'url.nabestaanden': '/nabestaanden',
  'url.vacatures': '/vacatures',
  'url.team': '/team',
  'url.privacy': '/privacy',
  'url.remuneration': '/beloningsbeleid',
  'url.beloningsbeleid': '/beloningsbeleid',
  'url.cookies': '/cookies',
  'url.uitvaart-ondernemers': '/uitvaart-ondernemers',
  'url.case-studies/t-mobile': '/case-studies/t-mobile',
  'url.case-studies/ov-chipkaart': '/case-studies/ov-chipkaart',
  accept: 'Accepteren',
  decline: 'Weigeren',
  cookieBanner:
    'We gebruiken cookies om jouw browserervaring te verbeteren en siteverkeer te analyseren. Door verder te bladeren of deze banner te sluiten, ga je akkoord met ons gebruik van cookies. Lees hier meer over in ons',
  portalUrl: 'https://app.closure.nl/portal/login',
  privacyPolicy: 'privacybeleid',
  remuneration: 'Beloningsbeleid',
  uo: 'Uitvaartondernemers',
  uo_singular: 'Uitvaartondernemer',
  heir: 'Nabestaanden',
  heir_singular: 'Nabestaande',
  company: 'Organisaties',
  company_singular: 'Organisatie',
  knowledgeBase: 'Helpdesk',
  caseStudies: 'Case Studies',
  contact: 'Contact',
  solutions: 'Oplossingen',
  resources: 'Resources',
  partners: 'Partners',
  aboutUs: 'Over ons',
  legal: 'Juridisch',
  team: 'Team',
  media: 'Pers & Media',
  vacancies: 'Vacatures',
  privacy: 'Privacy Policy',
  cookies: 'Cookie Policy',
  tos: 'Algemene Voorwaarden',
  homeHeaderTitle: 'De gespecialiseerde nabestaanden support desk',
  homeHeaderSubTitle:
    'Selecteer de optie die het beste bij jou aansluit om te zien wat wij voor je kunnen betekenen. ',
  homeHeaderButtonHeir: 'Nabestaanden',
  homeCard1Title: 'Ik wil abonnementen afwikkelen van een overleden persoon.',
  homeCard1Body: 'Niet toegepast',
  homeCard2Title: 'Hoe kunnen wij de juiste nabestaandenzorg aanbieden?',
  homeCard2Body: 'Niet toegepast',
  homeCard3Title:
    'Hoe kunnen wij onze operationele kosten verminderen en de kwaliteit van onze klantenservice behouden?',
  homeCard3Body: 'Niet toegepast',
  homeCard4Title: 'Hoe moeten wij omgaan met de digitale nalatenschap van onze klanten?',
  homeCard4Body: 'Niet toegepast',
  homeCard5Title:
    'Ik wil mijn nabestaanden kunnen voorzien van de juiste nazorg na een overlijden.',
  homeCard5Body: 'Niet toegepast',
  homeCta1Heirs: 'abonnementen opzeggen of overdragen',
  homeCtaHowItWorks: 'Hoe werkt het?',
  homeCta1Company: 'Bezoek de pagina voor organisaties',
  homeCtaDemo: 'Boek een Demo',
  homeCtaNBL: 'Bezoek Nabestaandenloket.nl',
  homeCtaMoreInfo: 'Meer informatie',
  heirHeaderTitle: 'Een efficiënte en veilige manier om een overlijden te melden bij organisaties',
  heirHeaderSubTitle:
    'Met de opzegdienst van Closure kan je bij meer dan 1600 organisaties kosteloos abonnementen opzeggen, wijzigen of overdragen.',
  heirHeaderCta1: 'Vind een organisatie',
  heirHeaderCta2: 'Hoe werkt het?',
  heirHowItWorksTitle: 'Hoe werkt het?',
  heirHowItWorksSubTitle:
    'Via een van onze partners kun je eenvoudig starten met de juiste afwikkeling van abonnementen.',
  heirHowItWorksStep1Title: 'Vind een partner',
  heirHowItWorksStep1Body:
    'Het startpunt van onze opzegdienst begint bij een van onze partners, zoals Odido of OV-chipkaart. Voor een overzicht van al onze partners, klik {link}.',
  heirHowItWorksStep2Title: 'Gegevens invullen',
  heirHowItWorksStep2Body:
    'Wij vragen nabestaanden om eenmalig de gegevens van de overledene in te vullen en de overlijdensakte te uploaden',
  heirHowItWorksStep3Title: 'Informeer alle organisaties',
  heirHowItWorksStep3Body:
    'Selecteer de organisaties waar je een melding van een overlijden wilt doen. Via het platform van Closure zal je vervolgens op de hoogte worden gehouden van de actuele status van de abonnementen.',
  heirCtaBoxTitle: 'Vind een partner die de opzegdienst van Closure aanbiedt',
  heirCtaBoxBody:
    'De opzegdienst van Closure biedt nabestaanden de uitkomst om eenvoudig abonnementen van een overleden naaste af te wikkelen bij een van onze partners. Zodra je een overlijden meldt bij een van onze partners, krijg je de mogelijkheid om ook direct meer dan 1600 andere organisaties op de hoogte stellen van het overlijdensgeval.',
  heirCtaBoxButton: 'Alle partners',
  companyHeaderTitle:
    'Een volledige oplossing voor jouw organisatie om meldingen van overlijden efficiënt af te handelen.',
  companyHeaderSubTitle:
    'De AI-gedreven nabestaandendesk van Closure ondersteunt organisaties bij het volledige proces rondom het verwerken van een overlijden, zowel digitaal als telefonisch.',
  companyHeaderCta1: 'Plan een kennismakingsgesprek',
  companyHeaderCta2: 'Hoe werkt het?',
  uspTitle: 'Bekijk de voordelen van onze service voor jouw branche.',
  uspChooseIndustry: 'Selecteer jouw branche',
  uspCompanyTitle: 'Voor jouw organisatie',
  uspHeirTitle: 'Voor jouw klanten',
  uspHowItWorksTitle: 'Hoe werkt het?',
  uspHowItWorksBody:
    'Closure biedt een digitale en telefonische oplossing aan nabestaanden voor het afwikkelen van abonnementen bij jouw organisatie. Hierbij vragen wij klanten eenmalig om de gegevens van de overledene met ons te delen. Deze gegevens worden met AI-technologie geverifieerd. Vervolgens zullen wij een first-time-right melding doorvoeren naar jouw organisatie.',
  uspHowItWorksStep1Title: 'Digitale & Telefonische intake',
  uspHowItWorksStep1Body:
    'De wensen van jullie organisatie worden in kaart gebracht via een digitaal formulier, zodat nabestaanden jullie producten en/of diensten kunnen afwikkelen. Om alle nabestaanden zo goed mogelijk te ondersteunen, bieden wij naast het digitale formulier ook telefonische ondersteuning bij eventuele vragen over het formulier.',
  uspHowItWorksStep2Title: 'Verifiëren van de melding van overlijden ',
  uspHowItWorksStep2Body:
    "De meldingen die bij Closure binnenkomen worden door de AI gedreven software en de nabestaandendesk van Closure geverifieerd, zoals de validatie van de overlijdensakte. Vervolgens wordt de gestandaardiseerde 'first-time-right' melding gegenereerd en overgedragen aan de organisatie.",
  uspHowItWorksStep3Title: 'De verwerking van een overlijdensmelding',
  uspHowItWorksStep3Body:
    "Op basis van het type IT-integratie, zijn we in staat om je een 'first time right' overlijdensmelding te sturen met de gewenste layout of via een API-integratie. In beide gevallen wordt er een complete melding van overlijden gedaan, waardoor je veel tijd bespaart.",
  uspTestimonialTitle: 'Recensies',
  uspTestimonialSubTitle:
    'Voor zowel organisaties als nabestaanden is Closure de ultieme uitkomst voor een klantvriendelijke en efficiënte afwikkeling van abonnementen na een overlijden.',
  uspTypeIntegrationTitle: 'Type integratie',
  uspTypeIntegrationLow: 'Zero impact IT',
  uspTypeIntegrationMedium: 'Medium impact IT',
  uspTypeIntegrationHigh: 'High impact IT',
  uspTypeIntegrationLowTitle: 'Zero IT impact oplossing',
  uspTypeIntegrationLowBody:
    'We weten dat IT roadmaps van organisaties complex zijn. Daarom kan de service van Closure in jouw organisatie geïmplementeerd worden zonder dat de inzet nodig is van jullie IT afdeling. Wij sturen de geverifieerde first-time-right overlijdensmeldingen via gestandaardiseerde e-mails naar jullie backoffice. Door jullie processen door en door te begrijpen, halen we de last weg van het verifiëren van persoonsgegevens, documentcontroles en het uitsturen van repetitieve herinneringen om foutieve gegevens te corrigeren.',
  uspTypeIntegrationMediumTitle: 'Medium impact IT oplossing',
  uspTypeIntegrationMediumBody:
    'Om een meer efficiënte workflow te realiseren, uploaden we geverifieerde overlijdensmeldingen en de vereiste documenten in jullie data source. Deze melding wordt vervolgens eenvoudig verwerkt door jullie backoffice.',
  uspTypeIntegrationHighTitle: 'Geïntegreerde IT oplossing',
  uspTypeIntegrationHighBody:
    'Door middel van op maat gemaakte API integraties, kunnen wij de overlijdensmeldingen direct in jullie backoffice of CRM oplossing verwerken. Hiermee nemen we al het repetitieve weg bij het afwikkelen van een overlijden.',
  companyFooterCtaTitle: 'Plan een kennismakingsgesprek',
  companyFooterCtaBody:
    'Plan een gratis kennismakingsgesprek om te zien hoe Closure kan helpen met het verbeteren van de afwikkeling van overlijdensmeldingen bij jouw organisatie.',
  companyFooterCtaButton: 'Plan een kennismakingsgesprek',
  modalDemoTitle: 'Plan een kennismakingsgesprek',
  modalDemoBody:
    'In het kennismakingsgesprek gaan we graag dieper in op hoe Closure jouw organisatie kan ondersteunen.',
  modalDemoEmail: 'E-mail adres',
  modalDemoName: 'Naam',
  modalDemoCompany: 'Organisatie',
  modalDemoButton: 'Plan een kennismakingsgesprek',
  uoHeaderTitle: 'Het complete nazorgplatform voor jouw nabestaanden',
  uoHeaderSubTitle:
    'Nabestaandenloket is ontwikkeld in samenwerking met de uitvaartsector om alle zaken rondom de nazorg bij een overlijden te bundelen op een digitaal platform in jouw eigen huisstijl.',
  uoHeaderButton: 'Bezoek Nabestaandenloket.nl',
  uoServiceTitle: 'Een complete nazorg om alle zaken te regelen na een overlijden.',
  uoServiceBody: 'Je kunt Nabestaandenloket in je eigen huisstijl aanbieden aan jouw nabestaanden.',
  uoService1Title: 'Account opzegdienst',
  uoService1Body:
    'De opzegdienst van Closure zit inbegrepen bij het platform van Nabestaandenloket. Laat jouw nabestaanden eenvoudig en snel aan de slag gaan met het informeren van organisaties na een overlijden.',
  uoService2Title: 'Extend your service',
  uoService2Body:
    'Alle belangrijke administratieve taken die na een overlijden moeten worden uitgevoerd zijn gepresenteerd in een gebruiksvriendelijk platform. Jullie klanten kunnen deze taken beheren, of kunnen hier andere naasten voor aanwijzen.',
  uoService3Title: 'Support service',
  uoService3Body:
    'Onze gespecialiseerde nabestaandendesk is bereikbaar via telefoon, chat en email voor jouw nabestaande om ondersteuning te bieden bij alle zaken rondom de nazorg.',
  uoFeature1Title: 'Eenvoudig alle abonnementen opzeggen, wijzigen of overdragen na een overlijden',
  uoFeature1Body:
    'De opzegdienst van Closure is geïntegreerd in het platform van Nabestaandenloket. Dit betekent dat jouw nabestaanden in een overzicht aan de slag kunnen gaan met zowel de administratieve zaken als de opzegdienst. Tevens vinden ze hier ook een overzicht van de actuele status van alle meldingen.',
  uoFeature2Title: 'Gemakkelijk en onder begeleiding alle administratieve zaken regelen',
  uoFeature2Body:
    'Een gestructureerde handleiding rondom alle administratieve zaken die geregeld moeten worden na een overlijden. Per categorie wordt er duidelijk beschreven hoe specifieke zaken moeten worden geregeld.',
  uoFeature3Title: 'Online afscheidspagina',
  uoFeature3Body:
    'Nabestaandenloket biedt een gepersonaliseerde afscheidspagina voor nabestaanden om meer verbintenis te creëren rondom de uitvaart. Op deze pagina is alle informatie over de uitvaart gemakkelijk terug te vinden, waaronder foto’s, belangrijke documenten en locaties. Ook kan de pagina worden gedeeld met naasten om herinneringen met elkaar te delen.',
  uoSecondaryBenefitsTitle: 'Waarom kiezen voor Nabestaandenloket?',
  uoSecondaryBenefits1Title: 'Opzegdienst inbegrepen',
  uoSecondaryBenefits1Body: 'Description',
  uoSecondaryBenefits2Title: 'In jouw eigen huisstijl',
  uoSecondaryBenefits2Body: 'Description',
  uoSecondaryBenefits3Title: 'Verleng je service tot na de uitvaart',
  uoSecondaryBenefits3Body: 'Omschrijving',
  uoSecondaryBenefits4Title: 'Administratieve handelingen',
  uoSecondaryBenefits4Body: 'Omschrijving',
  uoSecondaryBenefits5Title: 'Online afscheidspagina',
  uoSecondaryBenefits5Body: 'Omschrijving',
  uoSecondaryBenefits6Title: 'Ondersteuning voor nabestaanden',
  uoSecondaryBenefits6Body: 'Omschrijving',
  uoSecondaryBenefits7Title: 'Nodig familieleden uit',
  uoSecondaryBenefits7Body: 'Omschrijving',
  uoSecondaryBenefits8Title: 'Makkelijke integratie',
  uoSecondaryBenefits8Body: 'Omschrijving',
  uoSecondaryBenefits9Title: 'Inzicht in gebruik',
  uoSecondaryBenefits9Body: 'Omschrijving',
  uoNBLDeskTitle: 'Ondersteuning voor jouw nabestaanden',
  uoNBLDeskBody:
    'Onze supportafdeling voor nabestaanden is gespecialiseerd in het bieden van de juiste nazorg aan nabestaanden. Jouw nabestaanden kunnen bij ons terecht met allerlei vragen rondom de administratieve handelingen na een overlijden.',
  uoNBlDeskBenefitFeature1: '',
  uoNBlDeskBenefitFeature2: 'Open van ma-vr (08:30 - 21:00) en zaterdag (09:00 - 13:00)',
  uoNBlDeskBenefiFeature3: 'Bereikbaar via telefoon, chat en mail',
  teamHeaderCategory: 'Onze missie',
  teamHeaderTitle:
    'Om sleutelmomenten in het leven te veranderen in fijne ervaringen. Zowel voor mensen als organisaties.',
  teamOurStoryCategory: 'Ons verhaal',
  teamOurStoryTitle: 'Het begon allemaal...',
  teamOurStoryBody:
    'We kennen de pijn die komt kijken bij een overlijden. Niet alleen emotioneel, maar ook administratief gezien komt er een last bij kijken. Toen wij jaren geleden dit zelf hebben ervaren, is Closure ontstaan om een oplossing te ontwikkelen om het afwikkelen van abonnementen voor nabestaanden gemakkelijker te maken. Niet alleen nabestaanden, maar ook organisaties profiteren van de opzegdienst van Closure. We besparen namelijk de organisaties veel tijd en moeite door first-time-right geverifieerde meldingen van overlijden aan te leveren. Voor een lange tijd heeft Closure een digitale oplossing aangeboden. Echter, is er ook een grote groep mensen in Nederland die wat minder handig hiermee kunnen omgaan. Daarom hebben wij onze dienstverlening eind 2022 uitgebreid met een gespecialiseerde nabestaandendesk die zowel telefonisch, chat, email als per post te bereiken is. We zijn op een missie om naast meldingen van overlijden ook andere meldingen te verwerken die belangrijk zijn voor klanten. Vandaar is onze missie tegenwoordig: To turn key moments in life into great experiences for people and organizations.',
  teamStat1Value: '1/3',
  teamStat1Title:
    'Van alle overlijdensgevallen in Nederland worden op dit moment via Closure verwerkt.',
  teamStat2Value: '9.6',
  teamStat2Title: 'NPS score van nabestaanden',
  teamStat3Title: 'Onderdeel van de Goldman Sachs Launch Program 2022',
  teamSectionTitle: 'Ontmoet het team achter Closure',
  teamHiringTitle: 'Join Closure',
  teamHiringBody:
    'Heb jij de behoefte aan uitdagend werk binnen een snelgroeiende scale-up? Bekijk onze open vacatures.',
  teamHiringFeature1: 'Markt conform salaris',
  teamHiringFeature2: 'Kantoor naast Rotterdam Centraal',
  teamHiringFeature3: 'Een jong & gedreven A-player team',
  teamHiringButton: 'Vacatures',
  mediaHeaderTitle: 'Pers & Media',
  mediaArticleButton: 'Lees meer',
  partnersHeaderTitle: 'Vind een organisatie',
  partnerPlaceHolder: "Bijvoorbeeld 'AD'",
  partnersHeaderBody:
    'Vind een organisatie die de opzegdienst van Closure aanbiedt. Via deze partner kun je ook direct alle andere abonnementen aanvinken om op te zeggen of over te dragen.',
  partnersCardButtonBegin: 'Starten',
  partnersCardButtonMoreInfo: 'Meer informatie',
  partnersModalInfoTitle: 'Onofficiële Partners',
  partnersModalInfoBody:
    'Geen zorgen, je kunt ook bij onze onofficiële partners een account opzeggen of overdragen. Hiervoor moet je beginnen bij een officiële partner. Je kunt vervolgens via deze partner ook alle andere abonnementen aanvinken. Klik hieronder om onze officiële partners te bekijken.',
  partnersModalInfoButton: 'Sluiten',
  contactCategory: 'Contact',
  contactHeaderTitle: 'Wij helpen je graag verder.',
  contactHeirTitle: 'Voor nabestaanden',
  contactHeirBody:
    'Heb je als nabestaande vragen omtrent onze dienstverlening? Wij helpen je graag verder.',
  contactHeirButton: 'Chat',
  contactHeirOptional: 'of bel ',
  contactSalesTitle: 'Voor organisaties',
  contactSalesBody:
    'Laat hier je contactgegevens achter, dan zullen wij zo snel mogelijk contact met je opnemen.',
  contactSalesButton: 'Boek een demo',
  contactMediaTitle: 'Pers',
  contactMediaBody: 'Persaanvragen kunnen via de mail worden gestuurd naar pers@closure.nl',
  contactMediaButton: 'Mail',
  caseStudiesHeaderTitle: 'Case studies',
  caseStudiesHeaderBody:
    'Lees hieronder meerdere succesverhalen waarin wordt aangetoond hoe wij de volledige nabestaandenzorg overnemen van organisaties.',
  readmore: 'Lees meer',
  newsletter: 'Aanmelden voor onze nieuwsbrief',
  afm: 'Closure heeft voor het melden van het overlijden bij financiële dienstverleners een licentie van de Autoriteit Financiële Markten (AFM).',
  copyright:
    'Closure B.V. / Stationsplein 45 - Ruimte D3.111 / 3013AK Rotterdam KVK: 70792828 © Closure 2023. Alle rechten voorbehouden.',
  emailPlaceholder: 'E-mailadres',
  namePlaceholder: 'Volledige naam',
  companyPlaceholder: 'Naam van organisatie',
  organisationsSearchPlaceholder: 'Zoek naar organisaties',
  faqTitle: 'Veelgestelde vragen',
  uoFooterCTATitle: 'Bezoek Nabestaandenloket.nl',
  uoFooterCTABody:
    'Plan een gratis demo om te zien hoe NabestaandenLoket binnen jouw services als uitvaartondernemer past.',
  uoFooterCTAButton: 'Bezoek Nabestaandenloket.nl',
  uoCTA2Text: 'of bel ',
  uoPhoneNumber: '085 06 45 744',
  tMobileCaseStudySummary:
    "'Je wilt met zo'n gevoelig onderwerp mensen niet onnodig laten wachten, of ook maar iéts verkeerd doen' Bas Jansen, Manager Operations bij Odido",
  ovChipkaartCaseStudySummary:
    'In deze case study gaan we dieper in op onze samenwerking met OV-Chipkaart, het betaalsysteem achter het Nederlandse openbaar vervoer.',
  DPGMediaCaseStudySummary:
    'In deze case study gaan we dieper in op onze samenwerking met DPG Media, de media-gigant achter o.a. Het Parool en de Libelle',
  newsletterModalTitle: 'Aanmelden Nieuwsbrief',
  newsletterModalBody:
    'Abonneer op onze nieuwsbrief om op de hoogte te blijven rondom de laatste ontwikkelingen van Closure.',
  newsletterButton: 'Aanmelden',
  alreadyAccount: 'Of login als je al een account hebt',
  searchPartnersPlaceholder: 'Zoek naar organisaties',
  backToHome: 'Terug naar de startpagina',
  teamSectionGoldmanSachs: 'Wij zijn onderdeel van de Goldman Sachs Launch Program 2022',
  teamSectionPartnersProgram:
    "Door de jaren heen hebben we veel bereikt, hier een aantal programma's en awards.",
  organisationPlaceholder: 'Organisatie',
  officialPartners: 'Klik hier voor onze officiële partners',
  errorTitlePartners: 'Er is iets fout gegaan',
  errorBodyPartners: 'Ververs de pagina en probeer het opnieuw',
  phonenumber: 'Telefoonnummer',
  reason: 'Reden van interesse',
  errorMsgDemo: 'Vul alle verplichte velden in.',
  successMsgDemo: 'Aanvraag is succesvol verzonden',
  notificationsCaseStudy: 'meldingen',
  verifiedByClosure: 'Geverifieerd door Closure',
  deathNotificationVerifiedByClosure: 'Melding van overlijden geverifieerd',
  deathNotification: 'Melding van overlijden',
  moreInfo: 'Meer info',
  vacatures: 'Vacatures',
  viewButton: 'Bekijk',
  updateTOSDate: '18 mei 2022',
  here: 'hier'
};

export default messages;
