import { HeadProps } from "gatsby"
import React from "react"

import Layout from "../../components/Layout"
import Section from "../../components/Section"
import SimpleHeader from "../../components/SimpleHeader"
import HeadParent from "../../utils/header"
import RemunerationSEO from "../../content/seo/remuneration"

function Beloningsbeleid({ pageContext }) {
  return (
    <Layout pageContext={pageContext} navColor="bg-sand-600" navTextColor="dark">
      <SimpleHeader
        title="Beloningsbeleid"
        lastUpdated="24 maart 2025"
      />
      <Section bgColor="bg-sand" pt="pt-12 lg:pt-28" pb="pb-12 lg:pb-28">
        <div className="mx-auto max-w-3xl mb-6">
          <div className="text-plum space-y-4 text-base">
            <h1 className="text-plum text-lg">
              <strong>Het beloningsbeleid van Closure</strong>
            </h1>
            <p>
              Dit beloningsbeleid is opgesteld door Closure B.V. (hierna te noemen "
              <strong>Closure</strong>", “<strong>wij</strong>”, “<strong>ons</strong>” of “
              <strong>onze</strong>”), gevestigd aan het Stationsplein 45 - D3.111, 3013 AK Rotterdam,
              en ingeschreven bij de Kamer van Koophandel onder nummer 70792828.
            </p>

            <h2><strong>1. Doel van het beloningsbeleid</strong></h2>
            <p>
              Closure hanteert een beloningsbeleid dat is gericht op het bevorderen van een gezonde
              en effectieve risicobeheersing. Het beleid voorkomt prikkels voor het nemen van
              buitensporige risico’s en bevordert een klantgerichte, integere en duurzame bedrijfsvoering.
            </p>

            <h2><strong>2. Reikwijdte</strong></h2>
            <p>
              Dit beleid geldt voor alle medewerkers van Closure, inclusief de directie, controlerende
              functies en medewerkers met commerciële verantwoordelijkheden.
            </p>

            <h2><strong>3. Vaste beloning</strong></h2>
            <p>
              Alle medewerkers ontvangen een marktconforme vaste beloning, afhankelijk van hun functie,
              ervaring en verantwoordelijkheden.
            </p>

            <h2><strong>4. Variabele beloning</strong></h2>
            <p>
              Alleen B2B Sales medewerkers kunnen in aanmerking komen voor een
              <strong> beperkte bonus-malusregeling</strong>. De variabele beloning is maximaal 25% van het
              vaste jaarsalaris en is afhankelijk van zowel individuele prestaties als het behalen van team- en bedrijfsdoelen.
              Deze regeling bevat terugvorderingsmogelijkheden bij onterecht verkregen bonussen.
            </p>

            <h2><strong>5. Stock Appreciation Rights (SAR)</strong></h2>
            <p>
              Alle vaste medewerkers van Closure komen in aanmerking voor een SAR-regeling. Deze
              regeling biedt medewerkers de mogelijkheid om mee te profiteren van de waardeontwikkeling
              van de onderneming, zonder dat er aandelen worden uitgegeven.
            </p>

            <h2><strong>6. Risicobeheersing en belangenconflicten</strong></h2>
            <p>
              Het beloningsbeleid is zodanig opgezet dat het bijdraagt aan een integere bedrijfsvoering.
              Belangenconflicten worden actief voorkomen. Beloningen mogen nooit leiden tot
              onzorgvuldige of klantonvriendelijke dienstverlening.
            </p>

            <h2><strong>7. Transparantie en herziening</strong></h2>
            <p>
              Dit beloningsbeleid wordt jaarlijks herzien en indien nodig aangepast.
              De meest recente versie wordt gepubliceerd op <a href="https://www.closure.nl">www.closure.nl</a>.
            </p>

            <h2><strong>8. Contact</strong></h2>
            <p>
              Voor vragen over dit beloningsbeleid kunt u contact opnemen met Closure via{" "}
              <a href="mailto:info@closure.nl">info@closure.nl</a> of schriftelijk:
            </p>
            <p>
              Closure B.V.
              <br />
              Stationsplein 45, ruimte D3.111
              <br />
              3013 AK Rotterdam
            </p>
          </div>
        </div>
      </Section>
    </Layout>
  )
}

export default Beloningsbeleid

export function Head(props: HeadProps) {
  const { location } = props
  return (
    <HeadParent pageTitle="Beloningsbeleid" location={location}>
      <RemunerationSEO />
    </HeadParent>
  )
}
